import { useQuery } from "@tanstack/react-query";
import { widgetAxios } from "../../configs/axios/config";
import useDebounce from "../react/useDebounce";
type TuseQAutoCompleteParams = { search: string };
type axiosResponse = { suggestions: string[] };
export const useQAutoComplete = ({ search }: TuseQAutoCompleteParams) => {
   const dbSearch = useDebounce(search, 500);
   return useQuery({
      queryKey: ["autoComplete", dbSearch],
      enabled: dbSearch.length > 2,
      queryFn: () =>
         widgetAxios.get("/auto-complete", { params: { search: dbSearch } }).then<axiosResponse>(({ data }) => data),
   });
};
