import MainForm from "../MainForm/MainForm";
import "./App.css";

function App() {
   return (
      <div className="App">
         <MainForm />
      </div>
   );
}

export default App;
