import { SHrBodyFiller, SHrMainButton, SHrMainInput, TSHrMainInput_Option } from "@simplyhomes/react";
import styles from "./MainForm.module.scss";
import { useMemo, useState } from "react";
import { useQAutoComplete } from "../../hooks/queries/useQAutoComplete";
import { widgetAxios } from "../../configs/axios/config";
type validateResponse = {
   activeLeads: string[];
   pastLeads: string[];
   existingPropId: string | null;
   options: TOptions;
   defaults: {
      state: string | null;
      neighborhoodId: string | null;
      zipId: string | null;
      haId: string | null;
      marketId: string | null;
      yearBuilt: number | null;
      sqft: number | null;
      units: { beds: number; baths: number }[];
   };
};
type TOptions = {
   markets: TSHrMainInput_Option[];
   zipcodes: TSHrMainInput_Option[];
   housingAuthorities: TSHrMainInput_Option[];
   users: TSHrMainInput_Option[];
   contacts: TSHrMainInput_Option[];
   neighborhoods: (TSHrMainInput_Option & { market: string })[];
};
const MainForm = () => {
   const [validating, setValidating] = useState(false);
   const [submitting, setSubmitting] = useState(false);
   const [submitted, setSubmitted] = useState(false);
   const [isErrored, setIsErrored] = useState(false);

   const [search, setSearch] = useState("");
   // const [existing, setExisting] = useState(false);
   const [activeLeads, setActiveLeads] = useState<string[]>([]);
   const [pastLeads, setPastLeads] = useState<string[]>([]);
   const [existingPropId, setExistingPropId] = useState<string | null>("");
   const [address, setAddress] = useState("");
   const [marketId, setMarketId] = useState("");
   const [neighborhoodId, setNeighborhoodId] = useState("");
   const [housingAuthorityId, setHousingAuthorityId] = useState("");
   const [zipcodeId, setZipcode] = useState("");
   const [state, setState] = useState("");
   const [sqft, setSqft] = useState(0);
   const [yearBuilt, setYearBuilt] = useState(0);
   const [price, setPrice] = useState(0);
   const [reno, setReno] = useState(0);
   const [units, setUnits] = useState<{ beds: number; baths: number }[]>([]);
   const [user, setUser] = useState("");
   const [contactId, setContactId] = useState("");
   const [options, setOptions] = useState<TOptions>({
      markets: [],
      neighborhoods: [],
      zipcodes: [],
      housingAuthorities: [],
      users: [],
      contacts: [],
   });

   const qAutoComplete = useQAutoComplete({ search });
   const searchOptions = qAutoComplete.data?.suggestions.map((str) => ({ label: str, value: str })) || [];

   const { markets, zipcodes, housingAuthorities, contacts, users, neighborhoods } = options;
   const nhOptions = useMemo(() => neighborhoods.filter((o) => o.market === marketId), [marketId, neighborhoods]);

   const isValidUnits = units.length > 0 && units.every((u) => u.baths != null && u.beds);
   const isValidSubmit =
      !!address &&
      !!marketId &&
      !!neighborhoodId &&
      !!housingAuthorityId &&
      !!zipcodeId &&
      !!state &&
      !!sqft &&
      !!yearBuilt &&
      !!reno &&
      !!user &&
      !!contactId &&
      isValidUnits;
   const totalBed = units.reduce((acc, u) => acc + u.beds, 0);
   const totalBath = units.reduce((acc, u) => acc + u.baths, 0);
   const handleNewMarket = (newMarketId: string) => {
      setNeighborhoodId("");
      setMarketId(newMarketId);
   };
   const handleSearchOptionClick = async (address: string) => {
      setValidating(true);
      setActiveLeads([]);
      const validateResp = await widgetAxios
         .get("/validate-address", { params: { address } })
         .then<validateResponse>(({ data }) => data)
         .catch((e) => console.log({ e }));
      setValidating(false);
      if (!validateResp) return;
      setActiveLeads(validateResp.activeLeads);
      setPastLeads(validateResp.pastLeads);
      setExistingPropId(validateResp.existingPropId);
      if (validateResp.activeLeads.length > 0) return;
      setAddress(address);

      const { options, defaults } = validateResp;
      setOptions(options);
      setMarketId(defaults.marketId || "");
      setNeighborhoodId(defaults.neighborhoodId || "");
      setHousingAuthorityId(defaults.haId || "");
      setZipcode(defaults.zipId || "");
      setState(defaults.state || "");
      setSqft(defaults.sqft || 0);
      setYearBuilt(defaults.yearBuilt || 0);
      setUnits(defaults.units);
   };
   const handleSubmit = async () => {
      if (!isValidSubmit) return;
      setSubmitting(true);
      const property = {
         address,
         marketId,
         neighborhoodId,
         housingAuthorityId,
         zipcodeId,
         state,
         sqft,
         yearBuilt,
         price,
         reno,
         units,
         user,
         contactId,
         existingPropId,
      };
      await widgetAxios
         .post("/submit-property", { property })
         .then(() => {
            setSubmitted(true);
         })
         .catch((e) => {
            console.log({ e });
            setIsErrored(true);
         });
      setSubmitting(false);
   };

   const handleBackBtn = () => {
      setAddress("");
      setActiveLeads([]);
      setPastLeads([]);
      setExistingPropId("");
      setSearch("");
      setSubmitted(false);
      setIsErrored(false);
      setMarketId("");
      setNeighborhoodId("");
      setHousingAuthorityId("");
      setZipcode("");
      setState("");
      setSqft(0);
      setYearBuilt(0);
      setPrice(0);
      setReno(0);
      setUnits([{ beds: 0, baths: 0 }]);
   };

   const pastLeadStatusCount = useMemo(
      () =>
         pastLeads.reduce((prev, curr) => ({ ...prev, [curr]: (prev?.[curr] || 0) + 1 }), {} as Record<string, number>),
      [pastLeads]
   );

   const stateOptions = ["OH", "PA", "IN"].map((label) => ({ label, value: label }));
   // const test = (e: any) => {
   //    console.log({ e });
   // };

   return (
      <section className={`${styles.contentC}`}>
         <header>
            <b className={`cardHeader-s`}>Submit New Property</b>
         </header>
         {!address && (
            <div className={`${styles.searchC}`}>
               <SHrMainInput
                  title="Search Your Address"
                  value={search}
                  onChange={handleSearchOptionClick}
                  options={searchOptions}
                  type="select"
                  otherSelectProps={{
                     placeholder: "Type to search",
                     isSearchable: true,
                     onInputChange: setSearch,
                     isLoading: validating || qAutoComplete.isFetching,
                  }}
               />
            </div>
         )}
         {activeLeads.length > 0 && <b className={`${styles.dupeC}`}>Active Leads found on this address</b>}
         {pastLeads.length > 0 && (
            <b className={`${styles.dupeC}`}>
               This Property has{" "}
               {Object.entries(pastLeadStatusCount)
                  .map(([status, count]) => `${count} ${status.toLowerCase()} Lead${count > 1 ? "s" : ""}`)
                  .join(", ")}
            </b>
         )}
         {address && (
            <div className={`${styles.bodyC}`}>
               <div className={`${styles.formRow}`}>
                  <SHrMainButton onClick={handleBackBtn} type="back" style={{ height: "1.6rem", width: "1.6rem" }} />
                  <div>
                     <b>{address}</b>
                  </div>
                  <SHrMainButton type="bland" title="Refresh" onClick={() => handleSearchOptionClick(address)} />
               </div>
               <div className={`${styles.formRow}`}>
                  <SHrMainInput
                     hideOptionValues
                     title="Submitting by*"
                     type="search"
                     value={user}
                     onChange={setUser}
                     options={users}
                     bad={!user}
                  />
                  <SHrMainInput
                     hideOptionValues
                     title="Listing Agent*"
                     type="search"
                     value={contactId}
                     onChange={setContactId}
                     options={contacts}
                     bad={!contactId}
                  />
               </div>
               <div className={`${styles.formRow}`}>
                  <SHrMainInput
                     hideOptionValues
                     title="Market*"
                     type="search"
                     value={marketId}
                     onChange={handleNewMarket}
                     options={markets}
                     bad={!marketId}
                  />
                  <SHrMainInput
                     hideOptionValues
                     title="Neighborhood*"
                     type="search"
                     value={neighborhoodId}
                     onChange={setNeighborhoodId}
                     options={nhOptions}
                     bad={!neighborhoodId}
                  />
               </div>
               <div className={`${styles.formRow}`}>
                  <SHrMainInput
                     hideOptionValues
                     title="Housing Authority*"
                     type="search"
                     value={housingAuthorityId}
                     onChange={setHousingAuthorityId}
                     options={housingAuthorities}
                     bad={!housingAuthorityId}
                  />
               </div>
               <div className={`${styles.formRow}`}>
                  <SHrMainInput
                     title="Zipcode*"
                     options={zipcodes}
                     type="search"
                     value={zipcodeId}
                     onChange={setZipcode}
                     hideOptionValues
                  />
                  <SHrMainInput
                     title="State*"
                     options={stateOptions}
                     type="search"
                     value={state}
                     onChange={setState}
                     hideOptionValues
                  />
               </div>
               <div className={`${styles.formRow}`}>
                  <SHrMainInput
                     title="Year Built*"
                     bad={!yearBuilt}
                     type="text"
                     value={yearBuilt.toString()}
                     onChange={(v) => setYearBuilt(Number(v) || 0)}
                  />
                  <SHrMainInput
                     title="Square Feet*"
                     bad={!sqft}
                     type="text"
                     value={sqft.toString()}
                     onChange={(v) => setSqft(Number(v) || 0)}
                  />
               </div>
               <div className={`${styles.formRow}`}>
                  <SHrMainInput
                     title="Asking Price"
                     type="text"
                     value={price.toString()}
                     onChange={(v) => setPrice(Number(v) || 0)}
                     format="$"
                  />
                  <SHrMainInput
                     title="Reno Cost*"
                     bad={!reno}
                     type="text"
                     value={reno.toString()}
                     onChange={(v) => setReno(Number(v) || 0)}
                     format="$"
                  />
               </div>
               <div className={`${styles.unitsC}`}>
                  <div className={`${styles.unitsHeader}`}>
                     <p>
                        <b>{units.length} Units</b>
                        <span>
                           ({totalBed} Beds, {totalBath} Baths)
                        </span>
                     </p>
                     <SHrMainButton title="Add Unit" onClick={() => setUnits([...units, { beds: 0, baths: 0 }])} />
                  </div>
                  <ul className={`${styles.units}`}>
                     {units.map((u, i) => (
                        <li key={i} className={`${styles.unit}`}>
                           <SHrMainButton
                              style={{ height: "1.4rem", width: "1.4rem", flexShrink: 0 }}
                              type="close"
                              onClick={() => setUnits([...units.slice(0, i), ...units.slice(i + 1)])}
                           />
                           <p className={`cardHeader-s`}>#{1 + i}</p>
                           <SHrMainInput
                              title="Beds*"
                              type="text"
                              value={u.beds.toString()}
                              bad={!u.beds}
                              onChange={(v) =>
                                 setUnits([...units.slice(0, i), { ...u, beds: Number(v) || 0 }, ...units.slice(i + 1)])
                              }
                           />

                           <SHrMainInput
                              title="Baths*"
                              type="text"
                              value={u.baths.toString()}
                              bad={u.baths == null}
                              onChange={(v) =>
                                 setUnits([
                                    ...units.slice(0, i),
                                    { ...u, baths: Number(v) || 0 },
                                    ...units.slice(i + 1),
                                 ])
                              }
                           />
                        </li>
                     ))}
                  </ul>
               </div>
               <div className={`${styles.controlsC}`}>
                  {isErrored ? (
                     <b className={`${styles.errorC}`}>There was an error submitting! Please contact engineers!</b>
                  ) : submitted ? (
                     <>
                        <b className={`${styles.successC}`}>Successfully submited</b>
                        <SHrMainButton title="Submit another" type="secondary" onClick={handleBackBtn} />
                     </>
                  ) : submitting ? (
                     <SHrBodyFiller message="Submitting" />
                  ) : (
                     <SHrMainButton
                        enable={isValidSubmit}
                        title="Submit"
                        msg={`Submit ${address}\n(${totalBed} Beds, ${totalBath} Baths)?`}
                        onClick={handleSubmit}
                     />
                  )}
               </div>
            </div>
         )}
      </section>
   );
};

export default MainForm;
