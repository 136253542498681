import { useState, useEffect } from "react";

function useDebounce(value: any, delayMs: number) {
   const [debouncedValue, setDebouncedValue] = useState(value);

   useEffect(() => {
      const handler = setTimeout(() => setDebouncedValue(value), delayMs);
      return () => clearTimeout(handler);
   }, [value, delayMs]);

   return debouncedValue;
}

export default useDebounce;
